
import { Options, Vue } from 'vue-class-component';
import { GeneralSurveyClient } from '@/services/Services';
import BaseServerFilterTable from '@/components/customTable/baseServerFilterTable';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class DomandeAperte extends BaseServerFilterTable {

    list: OM.GeneralSurveyVM[] = [];
    tableRoot = null;

    init = function(){
        GeneralSurveyClient.getAll(this.requestFilter)
        .then(x => {
            this.list = x.items;
            this.totalItems = x.totalItemsCount;
        })
    }
}

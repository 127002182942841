import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "page_padding flex_spacebetween_centered" }
const _hoisted_2 = { class: "table_content_container three_column" }
const _hoisted_3 = { class: "body_content" }
const _hoisted_4 = { class: "column" }
const _hoisted_5 = { class: "column" }
const _hoisted_6 = { class: "column" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_head_column = _resolveComponent("head-column")!
  const _component_table_filter = _resolveComponent("table-filter")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_localized_text, {
        class: "fw_600",
        type: "h3",
        localizedKey: "backoffice_DomandeAperte",
        text: "Domande aperte"
      }),
      _createVNode("button", {
        type: "button",
        class: "ung_button violet",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/open-survey/')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "backoffice_Nuovo",
          text: "Nuovo"
        })
      ])
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_table_filter, {
        pagedRequest: _ctx.requestFilter,
        "onUpdate:pagedRequest": _cache[2] || (_cache[2] = ($event: any) => (_ctx.requestFilter = $event)),
        totalItems: _ctx.totalItems,
        onChangeSort: _ctx.changeSort,
        tableRoot: _ctx.tableRoot,
        "onUpdate:tableRoot": _cache[3] || (_cache[3] = ($event: any) => (_ctx.tableRoot = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_head_column, { param: "name" }, {
            default: _withCtx(() => [
              _createVNode(_component_localized_text, {
                localizedKey: "backoffice_Name",
                text: "Name"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_head_column, { sortable: false }, {
            default: _withCtx(() => [
              _createVNode(_component_localized_text, {
                localizedKey: "backoffice_QuestionNumber",
                text: "Question number"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_head_column, { sortable: false }, {
            default: _withCtx(() => [
              _createVNode(_component_localized_text, {
                localizedKey: "backoffice_PublishedOn",
                text: "Published On"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["pagedRequest", "totalItems", "onChangeSort", "tableRoot"]),
      _createVNode("div", _hoisted_3, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
          return (_openBlock(), _createBlock("div", {
            class: "body_list",
            key: item.identifier,
            onClick: ($event: any) => (_ctx.$router.push('/open-survey/' + item.identifier))
          }, [
            _createVNode("div", _hoisted_4, [
              _createVNode("span", null, _toDisplayString(item.name), 1)
            ]),
            _createVNode("div", _hoisted_5, [
              _createVNode("span", null, _toDisplayString(item.questions.length), 1)
            ]),
            _createVNode("div", _hoisted_6, [
              (item.published)
                ? (_openBlock(), _createBlock("span", _hoisted_7, _toDisplayString(_ctx.$filters.date(item.published.publishOn)), 1))
                : (_openBlock(), _createBlock("span", _hoisted_8, "-"))
            ])
          ], 8, ["onClick"]))
        }), 128)),
        (_ctx.totalItems == 0)
          ? (_openBlock(), _createBlock(_component_localized_text, {
              key: 0,
              class: "no_table_content",
              localizedKey: "backoffice_NessunRisultato",
              text: "Nessun risultato"
            }))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}